export enum ModuleRoute {
  AccessTags = '/access-tags',
  Dashboard = '/dashboard',
  Users = '/users',
  UserGroups = '/user-groups',
  Projects = '/projects',
  Map3D = '/3d-map',
  SwipeMap = '/swipe-map',
  Processing = '/processing',
}
