import { ImageryLayer } from 'cesium';
import { isEmpty, isNil } from 'lodash';
import { addSelfHostedOrtho, getLayerMetadata } from '..';
import { LayerType } from '../../../../../../../shared/api';
import { WorkspaceLayer } from '../../../../shared';
import { Map3DTerrainProviderType } from '../../../../../../types';
import { CesiumProxy } from '../../../../../../../shared/cesium';

type AddMapLayerProps = {
  layer: WorkspaceLayer;
  addTerrainLayerToMap?: (terrainLayerParams: Map3DTerrainProviderType) => void;
  show?: boolean;
  terrainProvider?: Map3DTerrainProviderType;
  cesiumProxy?: CesiumProxy | null;
};

export const addMapLayer = async ({
  layer,
  show,
  addTerrainLayerToMap,
  terrainProvider,
  cesiumProxy,
}: AddMapLayerProps): Promise<ImageryLayer | undefined> => {
  const mapLayerShow = show ?? !layer.show;
  let mapLayer: ImageryLayer | undefined = layer.mapLayer;
  if (mapLayer) {
    mapLayer.show = mapLayerShow;
  }
  const { type, sourceId, tiles, files, zIndex } = layer;
  const properties = getLayerMetadata(layer);

  switch (type) {
    case LayerType.Cesium:
      if (!terrainProvider && addTerrainLayerToMap && !isNil(sourceId)) {
        const terrainProvider =
          await cesiumProxy?.layerManager.setCesiumIonTerrainProvider(sourceId);
        addTerrainLayerToMap({
          show: mapLayerShow,
          terrainProvider,
        });
      }
      break;
    case LayerType.MapBox:
      mapLayer = cesiumProxy?.layerManager.addMapboxLayerToCesium(
        sourceId || '',
        {
          show: mapLayerShow,
          zIndex,
        },
      );
      break;
    case LayerType.Orthomosaic:
      if (!isEmpty(sourceId)) {
        mapLayer = cesiumProxy?.layerManager.addMapboxLayerToCesium(
          sourceId || '',
          {
            show: mapLayerShow,
            zIndex,
          },
        ) as ImageryLayer;
      } else if (cesiumProxy) {
        mapLayer = addSelfHostedOrtho(files || [], cesiumProxy);

        if (mapLayer) {
          mapLayer.show = mapLayerShow;
        }
      }
      break;
    case LayerType.MBTiles:
      if (!isNil(tiles)) {
        mapLayer = cesiumProxy?.layerManager.addMbTilesLayerToCesium(
          tiles,
          properties,
          {
            show: mapLayerShow,
            zIndex,
          },
        );
      }
      break;
    case LayerType.Vector:
    case LayerType.Contour:
      if (type == LayerType.Contour && isNil(tiles)) {
        break;
      }

      mapLayer = await cesiumProxy?.layerManager.addVectorTiledLayerToCesium(
        layer,
        {
          show: mapLayerShow,
          zIndex,
        },
      );

      break;
  }

  return mapLayer;
};

export const removeMapLayer = (
  cesiumProxy: CesiumProxy,
  mapLayer: ImageryLayer,
) => {
  cesiumProxy.layerManager.removeImageryLayers([mapLayer]);
};

export const updateWorkspaceLayerZIndices = (
  cesiumProxy: CesiumProxy,
  layers: WorkspaceLayer[],
  updateLayer: (WorkspaceLayerListObj) => void,
) => {
  layers.forEach((layer) => {
    const zIndex = cesiumProxy.layerManager.getZIndex(layer.mapLayer);

    updateLayer({
      [layer.id]: {
        ...layer,
        zIndex,
      },
    });
  });
};

export const reorderMapLayers = (
  cesiumProxy,
  layers: WorkspaceLayer[],
  sourceIndex: number,
  destinationIndex: number,
) => {
  let difference = Math.abs(destinationIndex - sourceIndex);
  const mapLayer = layers[sourceIndex].mapLayer;

  if (!isNil(mapLayer)) {
    if (destinationIndex > sourceIndex) {
      while (difference > 0) {
        cesiumProxy?.layerManager.lowerImageryLayer(mapLayer);
        difference--;
      }
    } else {
      while (difference > 0) {
        cesiumProxy?.layerManager.raiseImageryLayer(mapLayer);
        difference--;
      }
    }
  }
};
