import { camelCase, snakeCase } from 'lodash';

export const objectKeyCaseMapper = <T extends object, P extends object>(
  obj: T,
  mapFunc: typeof snakeCase | typeof camelCase,
): P => {
  if (obj instanceof Object) {
    const newObj: any = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (value instanceof Array) {
        value = value.map((v) => objectKeyCaseMapper(v, mapFunc));
      } else if (value instanceof Object) {
        value = objectKeyCaseMapper(value, mapFunc);
      }
      newObj[mapFunc(key)] = value;
    });
    return <P>newObj;
  } else {
    return obj;
  }
};

export const objectKeyToSnakeCaseMapper = <T extends object, P extends object>(
  obj: T,
): P => objectKeyCaseMapper(obj, snakeCase);

export const snakeCaseErrorToMessage = (snakeCaseString: string): string => {
  // Replace underscores (_) with spaces ( )
  return snakeCaseString
    .replace(/_/g, ' ')
    .replace(/\b\w/, (char) => char.toUpperCase());
};

export const objectKeyToCamelCaseMapper = <T extends object, P extends object>(
  obj: T,
): P => objectKeyCaseMapper(obj, camelCase);
