export enum Map3DSidebarOption {
  SlopeMap = 'Slope Map',
  Contour = 'Contour',
  Workspace = 'Workspace',
  Dataset = 'Dataset',
  ElevationTool = 'Elevation Tool',
  VolumeCalculation = 'Volume Calculation',
  Legend = 'Legend',
  BaseMap = 'BaseMap',
  GenerateAnalytics = 'Generate Analytics',
  AdditionalInformation = 'Additional Information',
}
