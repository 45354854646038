import { combineReducers } from '@reduxjs/toolkit';
import { dashboardReducers } from '../dashboard/dashboard-reducers';
import { map3dReducers } from '../map-3d/map-3d-reducers';
import { swipeMapReducers } from '../swipe-map/swipe-map-reducers';

const rootReducer = combineReducers({
  map3d: map3dReducers,
  dashboard: dashboardReducers,
  swipeMap: swipeMapReducers,
});

export default rootReducer;
